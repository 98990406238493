import React, { useState } from 'react';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BgGlassmorphism from './BgGlassmorphism';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });


const ForgetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await axiosInstance.post('/api/users/forget-password', { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error sending email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="nc-ForgetPassword relative overflow-hidden">
      <BgGlassmorphism />
      <div className="container relative z-10 mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-100 justify-center">
          Forgot Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="bg-white/10 backdrop-blur-lg rounded-3xl p-8">
            <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
              <label className="block">
                <span className="text-neutral-200 font-medium">Email</span>
                <Input
                  type="email"
                  name="email"
                  placeholder="example@email.com"
                  className="mt-1.5 bg-white/5 border-neutral-500"
                  value={email}
                  onChange={handleChange}
                  required
                />
              </label>
              <ButtonPrimary type="submit" disabled={loading}>
                {loading ? 'Sending...' : 'Send Reset Link'}
              </ButtonPrimary>
            </form>
            {message && <div className="mt-4 text-neutral-200 text-sm text-center">{message}</div>}
          </div>
          <span className="block text-center text-neutral-300">
            Remembered your password?{' '}
            <Link to="/login" className="text-primary-500 hover:text-primary-400 font-medium">
              Login
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;