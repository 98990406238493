import React, { useEffect, useState, useTransition, Suspense } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import Input from "shared/Input/Input"; // Assuming this is styled with Tailwind CSS
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchUsers,
  deleteUser,
  updateUserAdminStatus,
  fetchUserProfile,
} from "features/user/userSlice";
import { Address } from "data/types";

interface VehicleInformation {
  make: string;
  model: string;
  year: number | undefined;
  licensePlateNumber: string;
  isDefault?: boolean;
}

interface User {
  _id: string;
  email: string;
  fullName?: string;
  token: string;
  isAdmin?: boolean;
  addresses?: Address[];
  vehicleInformation?: VehicleInformation[];
  phoneNumber?: string;
}

const ITEMS_PER_PAGE = 10;

const UserManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { users, userInfo, loading, error } = useAppSelector(
    (state) => state.users
  );
  const [isPending, startTransition] = useTransition();
  console.log("userInfo", userInfo);
  console.log(users);
  const currentUser = userInfo ? userInfo._id : "";
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userForm, setUserForm] = useState({
    email: "",
    password: "",
    isAdmin: false,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const filteredUsers = users.filter((user) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      user.email?.toLowerCase().includes(searchLower) ||
      user.fullName?.toLowerCase().includes(searchLower) ||
      user.phoneNumber?.toLowerCase().includes(searchLower)
    );
  });

  // Calculate pagination
  const totalPages = Math.ceil(filteredUsers.length / ITEMS_PER_PAGE);
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Wrap search term updates in startTransition
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      setSearchTerm(e.target.value);
    });
  };

  // Wrap page changes in startTransition
  const handlePageChange = (pageNumber: number) => {
    startTransition(() => {
      setCurrentPage(pageNumber);
    });
  };

  // Wrap admin status changes in startTransition
  const handleAdminChange = (userId: string, isAdmin: boolean) => {
    if (currentUser && userInfo && userId !== currentUser) {
      startTransition(() => {
        dispatch(updateUserAdminStatus({ userId, isAdmin }));
      });
    }
  };

  // Wrap delete operation in startTransition
  const handleDeleteUser = async (userId: string) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        setDeleteError(null);
        await dispatch(deleteUser(userId)).unwrap();
        startTransition(() => {
          dispatch(fetchUsers());
        });
      } catch (err: any) {
        startTransition(() => {
          setDeleteError(err.message || 'Failed to delete user');
        });
        setTimeout(() => {
          startTransition(() => {
            setDeleteError(null);
          });
        }, 3000);
      }
    }
  };

  // Use startTransition in useEffect
  useEffect(() => {
    startTransition(() => {
      dispatch(fetchUsers());
      dispatch(fetchUserProfile());
    });
  }, [dispatch]);

  // Wrap search term effect in startTransition
  useEffect(() => {
    startTransition(() => {
      setCurrentPage(1);
    });
  }, [searchTerm]);

  return (
    <div className="p-5">
      {deleteError && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {deleteError}
        </div>
      )}
      {(isPending || loading) && (
        <div className="text-center py-4">
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-primary-500 border-t-transparent"></div>
        </div>
      )}
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Users</h2>
          <div className="relative w-64">
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm("")}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            )}
          </div>
        </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Name</th>

              <th className="px-4 py-2">Phone</th>
              <th className="px-4 py-2">Email</th>
              <th className="px-4 py-2">Address</th>
              <th className="px-4 py-2">Admin</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedUsers.map((user: User) => (
              <tr key={user._id}>
                <td className="border px-4 py-2">{user.fullName}</td>
                <td className="border px-4 py-2">{user.phoneNumber}</td>

                <td className="border px-4 py-2">{user.email}</td>
                <td className="border px-4 py-2">
                  {user.addresses && user.addresses.length > 0 ? (
                    user.addresses.map((address, index) => (
                      <div key={address._id}>
                        <p>{address.name}</p>
                        <p>{address.street}</p>
                        <p>{address.city}, {address.country}</p>
                        {user.addresses && index < user.addresses.length - 1 && ( // Add separator for all but the last address
                          <hr className="my-2" />
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No addresses available</p>
                  )}
                </td>

                <td className="border px-4 py-2">
                  <input
                    type="checkbox"
                    checked={user.isAdmin}
                    onChange={(e) =>
                      handleAdminChange(user._id, e.target.checked)
                    }
                  />
                </td>
                <td className="border px-4 py-2">
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white py-2 px-2 rounded"
                    onClick={() => handleDeleteUser(user._id)}
                    disabled={user._id === currentUser} // Prevent deleting own account
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <div className="flex justify-center items-center space-x-2 mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-3 py-1 rounded ${currentPage === 1
                ? 'bg-gray-200 text-black cursor-not-allowed'
                : 'bg-white text-black hover:bg-primary-300 hover:text-white'
                }`}
            >
              Previous
            </button>

            {/* Page Numbers */}
            <div className="flex space-x-1">
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .filter(page => {
                  // Show first page, last page, current page, and pages around current
                  return (
                    page === 1 ||
                    page === totalPages ||
                    Math.abs(currentPage - page) <= 2
                  );
                })
                .map((page, index, array) => (
                  <React.Fragment key={page}>
                    {index > 0 && array[index - 1] !== page - 1 && (
                      <span className="px-2">...</span>
                    )}
                    <button
                      onClick={() => handlePageChange(page)}
                      className={`px-3 py-1 rounded ${currentPage === page
                        ? 'bg-primary-600 text-white'
                        : 'bg-gray-200 hover:bg-primary-300 text-black'
                        }`}
                    >
                      {page}
                    </button>
                  </React.Fragment>
                ))}
            </div>

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-3 py-1 rounded ${currentPage === totalPages
                ? 'bg-gray-200 text-black cursor-not-allowed'
                : 'bg-white text-black hover:bg-primary-300 hover:text-white'
                }`}
            >
              Next
            </button>
          </div>
        )}

        {/* Results Summary */}
        <div className="text-gray-600 text-sm mt-4 text-center">
          Showing {Math.min(ITEMS_PER_PAGE * (currentPage - 1) + 1, filteredUsers.length)} to{' '}
          {Math.min(ITEMS_PER_PAGE * currentPage, filteredUsers.length)} of {filteredUsers.length} users
        </div>

        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>
    </div>
  );
};

export default React.memo(UserManager);
