import React, { useEffect, useState } from "react";
import MyRouter from "routers/index";
import { requestNotificationPermission, getFCMToken, onForegroundMessage } from "./utils/firebaseConfig";
import { sendTokenToBackend } from "./utils/NotificationService";

function App() {
  const [token, setToken] = useState("");
  
  useEffect(() => {
    const initializeNotifications = async () => {
      // Check if user is logged in
      const authToken = localStorage.getItem("token");
      
      // Only request notifications if user is logged in
      if (authToken) {
        const permission = await requestNotificationPermission();
        if (permission === "granted") {
          const token = await getFCMToken();
          if (token) {
            await sendTokenToBackend(token);
          }
        }
      }
    };

    initializeNotifications();

    // Set up foreground message listener only if user is logged in
    if (localStorage.getItem("token")) {
      onForegroundMessage((payload) => {
        const title = payload.notification?.title || 'New Notification';
        const options = {
          body: payload.notification?.body || 'You have a new message.',
          icon: '/logo192.png',
        };
        new Notification(title, options);
      });
    }
  }, []);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}
export default App;
