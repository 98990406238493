import React from "react";
import AboutUsImage from "images/aboutus-image.png";
import ServicesImage from "images/aboutus-services.jpeg";
import ProductsImage from "images/aboutus-product.jpeg";
const AboutUsScreen = () => {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16">
          <h2 className="font-semibold text-2xl md:text-3xl lg:text-4xl text-white text-center">
            About Us - Carveel Car Wash
          </h2>

          <p className="mt-8 text-neutral-300 md:text-lg text-center max-w-4xl mx-auto px-6">
            Welcome to Carveel Car Wash, your premier destination for top-tier
            automotive care. Established in 2023, we transform every vehicle into a
            shining example of pristine perfection.
          </p>

          {/* Story Section */}
          <div className="mt-16 lg:mt-24">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-5">
              <div className="space-y-5">
                <h3 className="text-2xl md:text-3xl font-semibold text-white">
                  Our Story
                </h3>
                <div className="text-neutral-300 space-y-4">
                  <p>
                    Carveel Car Wash was born out of a love for cars and a desire to
                    create an oasis for car enthusiasts and everyday drivers alike.
                  </p>
                  <p>
                    We understand that your vehicle is more than just transportation;
                    it's a reflection of your personality and a significant investment.
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src={AboutUsImage}
                  className="w-full rounded-2xl object-cover shadow-xl"
                  alt="About Us"
                  style={{ maxHeight: "400px" }}
                />
              </div>
            </div>
          </div>

          {/* Services Section */}
          <div className="mt-16 lg:mt-24">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-5">
              <div className="relative order-2 md:order-1">
                <img
                  src={ServicesImage}
                  className="w-full rounded-2xl object-cover shadow-xl"
                  alt="Our Services"
                  style={{ maxHeight: "400px" }}
                />
              </div>
              <div className="space-y-5 order-1 md:order-2">
                <h3 className="text-2xl md:text-3xl font-semibold text-white">
                  Our Services
                </h3>
                <div className="text-neutral-300 space-y-4">
                  <p>
                    At Carveel Car Wash, we offer comprehensive services designed to
                    meet all your car care needs. From full-service car washes to
                    ceramic coating, we have the expertise to handle it all.
                  </p>
                  <p>
                    Our experienced technicians use only the finest products and
                    techniques to ensure your car receives the care it deserves.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Products Section */}
          <div className="mt-16 lg:mt-24">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-5">
              <div className="space-y-5">
                <h3 className="text-2xl md:text-3xl font-semibold text-white">
                  Our Products
                </h3>
                <div className="text-neutral-300 space-y-4">
                  <p>
                    We offer a curated selection of premium car care products.
                    Whether you're looking for the perfect car shampoo, a
                    high-quality wax, or a complete detailing kit, you'll find
                    everything you need.
                  </p>
                  <p>
                    We choose products that are not only effective but also
                    environmentally friendly, ensuring you can care for your car
                    without harming the planet.
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src={ProductsImage}
                  className="w-full rounded-2xl object-cover shadow-xl"
                  alt="Our Products"
                  style={{ maxHeight: "400px" }}
                />
              </div>
            </div>
          </div>

          {/* Join Us Section */}
          <div className="mt-16 lg:mt-24 text-center max-w-4xl mx-auto px-6">
            <h3 className="text-2xl md:text-3xl font-semibold text-white mb-8">
              Join the Carveel Family
            </h3>
            <p className="text-neutral-300 md:text-lg">
              Whether you're a car enthusiast, a daily commuter, or a weekend
              warrior, Carveel Car Wash is here to elevate your car care
              experience. Book us today to discover the difference that passion
              and expertise can make in your vehicle's appearance and
              performance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsScreen;
