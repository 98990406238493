import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { CarType } from "data/types";

export interface CarCardProps {
  className?: string;
  taxonomy: CarType;
  isSelected: boolean;
  onSelect: () => void;
}

const CarCard: FC<CarCardProps> = ({
  className = "flex-1",
  taxonomy,
  isSelected,
  onSelect,
}) => {
  const { name, thumbnail } = taxonomy || {};
  return (
    <div
      className={`nc-CarCard relative flex flex-col w-full group rounded-2xl z-0 overflow-hidden ${isSelected ? "border-4 border-amber-700" : ""
        } ${className}`}
      data-nc-id="CarCard"
      onClick={onSelect}
      style={
        isSelected ? { boxShadow: "0 0 10px rgba(251, 191, 36, 0.7)" } : {}
      }
    >
      <div className="aspect-w-16 aspect-h-8 sm:aspect-h-8 xl:aspect-h-8 w-full h-0">
        <NcImage
          src={thumbnail}
          onError={(e) => {
            e.currentTarget.src = 'path/to/fallback-image.png'; // Set a fallback image
          }}
          className="object-cover absolute inset-0 w-full rounded-2xl group-hover:scale-105 transition-transform duration-300"
        />
      </div>
      <div className="p-2 sm:p-6 text-white">
        <h2 className={`text-lg lg:text-xl font-semibold text-center`}>
          {name}
        </h2>
      </div>
    </div>
  );
};

export default CarCard;
