import React from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  cancelOrder,
  fetchOrders,
  getMyOrders,
  rescheduleOrder,
} from "features/orders/orderSlice";
import { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonDanger from "shared/Button/ButtonDanger";
import Modal from "shared/Modal/Modal";
import DatePicker from "react-datepicker";
import { Timeslot } from "./CartScreen";
import { fetchTimeslot } from "features/timeslot/timeslotSlice";

const MyOrders = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [newDate, setNewDate] = useState<Date | null>(null);
  const [newTime, setNewTime] = useState<string | null>("");
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(null); // State for selected timeslot

  const dispatch = useAppDispatch();
  const { orders, loading, error } = useAppSelector((state) => state.orders);
  const { timeslots, loading: loadingTimeslots, error: errorTimeslots } = useAppSelector((state) => state.timeslots); // Select timeslots from state

  useEffect(() => {
    dispatch(getMyOrders());
    dispatch(fetchTimeslot());
    console.log(orders)
    console.log(selectedTimeslot)
  }, [dispatch]);

  const handleSelectTimeslot = (timeslot: Timeslot) => {
    if (selectedTimeslot && selectedTimeslot._id === timeslot._id) {
      setSelectedTimeslot(null); // Deselect if the same timeslot is clicked again
      console.log(`Selected Time Slot 1 ${timeslot.slotTime}`)
    } else {
      setSelectedTimeslot(timeslot); // Select the new timeslot
      console.log(`Selected Time Slot 2 ${timeslot.slotTime}`)

    }
  };

  const getCurrentTimeInDubai = () => {
    // Dubai is UTC+4
    const dubaiOffset = 4;
    const now = new Date();
    const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
    return new Date(utc + (3600000 * dubaiOffset));
  };
  const isToday = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  const getAvailableTimeslots = () => {
    if (!newDate) return [];

    const currentTime = getCurrentTimeInDubai();

    return timeslots.filter(timeslot => {
      if (!timeslot.isAvailable) return false;

      // If selected date is today, only show future timeslots
      if (isToday(newDate)) {
        const [hours, minutes] = timeslot.slotTime.match(/(\d+):(\d+)/)?.slice(1) || [];
        const slotHour = parseInt(hours);
        const isPM = timeslot.slotTime.includes('PM');
        const slot24Hour = isPM && slotHour !== 12 ? slotHour + 12 : slotHour;

        const slotDate = new Date(newDate);
        slotDate.setHours(slot24Hour, parseInt(minutes));

        return slotDate > currentTime;
      }

      return true;
    });
  };

  const handleOrderDetails = (orderId: string) => {
    const order = orders.find((order: any) => order._id === orderId);
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleReschedule = () => {
    setRescheduleModalOpen(true)
  }

  const handleConfirmReschedule = () => {
    if (selectedOrder && newDate) {
      const originalDateTime = new Date(selectedOrder.date);
      const currentTime = new Date();
      const timeDifferenceInHours = (originalDateTime.getTime() - currentTime.getTime()) / (1000 * 60 * 60);

      if (timeDifferenceInHours <= 12) {
        alert(`You can only reschedule the order until 12 hours before the original booking time (i.e., ${originalDateTime.toString()}).`);
        return; // Exit the function if the condition is not met
      }

      console.log("Data being sent to backend:", {
        id: selectedOrder._id,
        newDate: newDate.toISOString(),
        newTime: selectedTimeslot?.slotTime || "",
      });
      dispatch(rescheduleOrder({
        id: selectedOrder._id,
        newDate: newDate.toISOString(),
        newTime: selectedTimeslot?.slotTime || "",
      }));
      setRescheduleModalOpen(false); // Close the modal after confirming
    } else {
      alert("Please select a valid date for rescheduling.");
    }
  };

  const cancelBooking = (id: string) => {
    dispatch(cancelOrder({ id}));
    setShowModal(false);
  };


  // Calculate time difference for button disabling
  const originalDateTime = selectedOrder ? new Date(selectedOrder.date) : null;
  const currentTime = new Date();
  const timeDifferenceInHours = originalDateTime ? (originalDateTime.getTime() - currentTime.getTime()) / (1000 * 60 * 60) : Infinity;

  // Determine if buttons should be disabled
  const isDisabled = timeDifferenceInHours <= 12;
  // Sort orders by date before rendering
  const sortedOrders = [...orders].sort((a, b) => {
    const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
    const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
    return dateB - dateA;
  });

  //display orders in table
  return (
    <div className="p-5">
      <h1 className="container text-xl font-bold mb-4">My Orders</h1>
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <table className="min-w-full table-auto border border-gray-300">
            <thead>
              <tr>
              <th className="px-4 py-2">Order ID</th>
            <th className="px-4 py-2">Date</th>
            <th className="px-4 py-2">Total</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Address</th>
            <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedOrders.map((order: any) => (
                <tr key={order._id} >
                  <td className="border px-4 py-2">{order._id}</td>
                  <td className="border px-4 py-2">
                    {order.createdAt ? order.createdAt.substring(0, 10) : "N/A"}
                  </td>
                  <td className="border px-4 py-2">AED &nbsp;{order.total}</td>
                  <td className={`border px-4 py-2 ${order.status === "Pending" ? "bg-yellow-500" : order.status === "Paid" ? "bg-green-500" : order.status === "In Process" ? "bg-blue-500" : order.status === "Completed" ? "bg-green-800" : "bg-gray-500"}`}>
                {order.status}
              </td>
              <td className="border px-4 py-2">
                <p><strong>Name:</strong> {order.address.name}</p>
                <p><strong>Street:</strong> {order.address.street}</p>
                <p><strong>City:</strong> {order.address.city}</p>
                <p><strong>Country:</strong> {order.address.country}</p>
              </td>
                <td className="border px-4 py-2">
                  <ButtonPrimary onClick={() => handleOrderDetails(order._id)}>
                    View Details
                  </ButtonPrimary>
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <div className="p-6 bg-gray-800 rounded-lg">
            <h2 className="text-white text-lg font-bold mb-4">Order Details</h2>
            <p className="text-white">
              <span className="font-bold">Order ID:</span> {selectedOrder._id}
            </p>
            <p className="text-white">
              <span className="font-bold">Total Price:</span> AED &nbsp;
              {selectedOrder.total}
            </p>
            <p className="text-white">
              <span className="font-bold">Status:</span> {selectedOrder.status}
            </p>
            <p className="text-white">
              <span className="font-bold">Date:</span> {new Date(selectedOrder.date).toLocaleString()}
            </p>
            <p className="text-white">
              <span className="font-bold">Time:</span> {selectedOrder.time}
            </p>
            <div className="flex justify-around mt-4">
              <ButtonDanger
                onClick={() => cancelBooking(selectedOrder._id)}
                className="mt-4 mx-1"
                disabled={isDisabled}
              >
                Cancel Booking
              </ButtonDanger>
              <ButtonPrimary
                onClick={handleReschedule}
                className="mt-4 mx-1"
                disabled={isDisabled}
              >
                Reschedule
              </ButtonPrimary>
              <ButtonPrimary
                onClick={() => setShowModal(false)}
                className="mt-4"
              >
                Close
              </ButtonPrimary>
            </div>
            <p className="text-yellow-500 text-center mt-2">
              You can cancel and reschedule only 12 hours before the booking time.
            </p>
          </div>
        </Modal>
      )}
      {/* Reschedule Modal */}
      {rescheduleModalOpen && (
        <Modal isOpen={rescheduleModalOpen} onClose={() => setRescheduleModalOpen(false)}>
          <div className="p-6 bg-gray-800 rounded-lg">
            <h2 className="text-white">Reschedule Order</h2>
            <DatePicker
              selected={newDate}
              onChange={(date: Date) => setNewDate(date)}
              placeholderText="Select a date"
              minDate={new Date()}
              dateFormat="MMMM d, yyyy"
              className="form-input rounded-md px-3 py-2 border border-gray-300"
            />
            <div className="mt-4">
              <label className="text-white">Select Timeslot:</label>
              <div className="flex mt-4 space-x-4 overflow-auto">
                {getAvailableTimeslots().map((timeslot) => (
                  <button
                    key={timeslot._id}
                    className={`w-32 p-4 rounded-lg text-center text-black font-medium focus:outline-none flex-shrink-0 ${selectedTimeslot && selectedTimeslot._id === timeslot._id ? "bg-primary-700" : "bg-neutral-400"}`}
                    onClick={() => handleSelectTimeslot(timeslot)}
                  >
                    {timeslot.slotTime}
                  </button>
                ))}
                {getAvailableTimeslots().length === 0 && (
                  <p className="text-yellow-500">No available timeslots for this date</p>
                )}
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <ButtonPrimary onClick={handleConfirmReschedule}>Confirm Reschedule</ButtonPrimary>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MyOrders;
