import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { Address, VehicleInformation } from "data/types";
import { Timeslot } from "screens/CartScreen";
import { useAppDispatch } from "app/hooks";

interface CartItem {
  id: string;
  type: "Package" | "Service" | "Product";
  name: string;
  price: number;
  image?: string;
  quantity: number;
}

interface OrderData {
  car: VehicleInformation | null;
  date: Date | null;
  time: Timeslot | null;
  cartItems: CartItem[];
  address: Address | null;
  subtotal: number;
  discount: number;
  total: number;
}

interface Order {
  _id?: string; // Optional because it's generated by the server
  user: string;
  car: VehicleInformation | null;
  date: Date | null;
  time: Timeslot | null;
  items: CartItem[];
  address: Address | null;
  subtotal: number;
  discount: number;
  total: number;
  status: "Pending" | "Paid" | "Completed" | "Cancelled";
  isPaid: boolean;
  createdAt?: Date; // Optional because it's generated by the server
  updatedAt?: Date; // Optional because it's generated by the server
}

interface OrderState {
  orders: Order[];
  loading: boolean;
  error: string | null;
}

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const initialState: OrderState = {
  orders: [],
  loading: false,
  error: null,
};

export const fetchOrders = createAsyncThunk<
  Order[],
  undefined,
  { rejectValue: string }
>("orders/fetchOrders", async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axiosInstance.get("/api/orders", config);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const rescheduleOrder = createAsyncThunk<
  Order,
  { id: string; newDate: string; newTime: string | null },
  { rejectValue: string }
>(
  "order/rescheduleOrder",
  async ({ id, newDate, newTime }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      console.log("Sending request with data:", { id, newDate, newTime })

      const response = await axiosInstance.put(
        `/api/orders/${id}/reschedule`,
        { id, newDate, newTime },
        config
      );
      return response.data; // Return the updated order data
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);
export const cancelOrder = createAsyncThunk<
  { id: string },
  { id: string },
  { rejectValue: string }
>(
  "order/cancelOrder",
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      console.log('Sending request with data',{id})
      await axiosInstance.put(`/api/orders/${id}/cancel`,{id}, config);
      return { id }; // Return the orderId to update the state
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);


export const getMyOrders = createAsyncThunk<
  Order[],
  undefined,
  { rejectValue: string }
>("orders/getMyOrders", async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Access token from localStorage
      },
    };
    const response = await axiosInstance.get("/api/orders/myorders", config);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async (orderData: OrderData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/orders`,
        orderData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to create order');
    }
  }
);

export const orderDetails = createAsyncThunk<
  Order,
  string,
  { rejectValue: string }
>("orders/orderDetails", async (id, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Set Content-Type header for JSON data
      },
    };
    const response = await axiosInstance.get(`/api/orders/${id}`, config);
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const payOrder = createAsyncThunk<
  Order,
  { id: string; paymentResult: any },
  { rejectValue: string }
>("orders/payOrder", async (payload, { rejectWithValue }) => {
  try {
    const { id, paymentResult } = payload;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Set Content-Type header for JSON data
      },
    };
    const response = await axiosInstance.put(
      `/api/orders/${id}/pay`,
      paymentResult,
      config
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data.message || error.message);
  }
});

export const markOrderAsPaid = createAsyncThunk<
  Order,
  { orderId: string },
  { rejectValue: string }
>(
  "orders/markOrderAsPaid",
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axiosInstance.put(
        `/api/orders/${orderId}/pay`,
        { orderId },
        config
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

export const markOrderAsInProcess = createAsyncThunk<
  Order,
  { orderId: string },
  { rejectValue: string }
>(
  "orders/markOrderAsInProcess",
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axiosInstance.put(
        `/api/orders/${orderId}/in-process`,
        { orderId },
        config
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);

export const markOrderAsCompleted = createAsyncThunk<
  Order,
  { orderId: string },
  { rejectValue: string }
>(
  "orders/markOrderAsCompleted",
  async ({ orderId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axiosInstance.put(
        `/api/orders/${orderId}/completed`,
        { orderId },
        config
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data.message || error.message);
    }
  }
);



export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearOrders: (state) => {
      state.orders = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getMyOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(getMyOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.orders.push(action.payload);
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(orderDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(orderDetails.fulfilled, (state, action) => {
        state.loading = false;
        const order = action.payload;
        const existingOrderIndex = state.orders.findIndex(
          (existingOrder) => existingOrder._id === order._id
        );
        if (existingOrderIndex !== -1) {
          state.orders[existingOrderIndex] = order;
        } else {
          state.orders.push(order);
        }
      })
      .addCase(orderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
     
      .addCase(rescheduleOrder.fulfilled, (state, action) => {
        const { _id } = action.payload; // Get orderId from the payload
        const { newDate, newTime } = action.meta.arg; // Access newDate and newTime from meta.arg

        // Find the index of the order in the state
        const orderIndex = state.orders.findIndex((order) => order._id === _id);

        if (orderIndex !== -1) {
          const newTimeslot: Timeslot = {
            _id: newDate + newTime,
            slotTime: newTime || "",
            isAvailable: true,
          };

          // Update the order's date and time
          state.orders[orderIndex].date = new Date(`${newDate}T${newTime}:00`);
          state.orders[orderIndex].time = newTimeslot;
        }
      })
      // Handle rescheduleOrder rejection
      .addCase(rescheduleOrder.rejected, (state, action) => {
        state.error = action.payload as string;
      })

      // Handle cancelOrder fulfillment
      .addCase(cancelOrder.fulfilled, (state, action) => {
        const { id } = action.payload;
        // Remove the cancelled order from the list
        state.orders = state.orders.filter((order) => order._id !== id);
      })

      // Handle cancelOrder rejection
      .addCase(cancelOrder.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      //MarkOrderAsInProcess
      .addCase(markOrderAsInProcess.fulfilled,(state,action)=>{
        const updatedOrder = action.payload;
        const existingOrderIndex = state.orders.findIndex(
          (order)=>order._id ===updatedOrder._id
        );
        if(existingOrderIndex!==-1){
          state.orders[existingOrderIndex] = updatedOrder
        }
      })
      .addCase(markOrderAsCompleted.fulfilled,(state,action)=>{
        const updatedOrder = action.payload;
        const existingOrderIndex = state.orders.findIndex(
          (order) => order._id === updatedOrder._id
        );
        if (existingOrderIndex !== -1) {
          state.orders[existingOrderIndex] = updatedOrder;
        }
      })
  },
});

export default orderSlice.reducer;
