import React, { FC, useEffect, useRef } from "react";
import AdsCard, { Ad } from "./AdsCard";
import { fetchActiveAds } from "../features/ads/adsSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";

const Ads: FC = () => {
  const dispatch = useAppDispatch();
  const { ads, loading, error } = useAppSelector((state) => state.ads);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchActiveAds());
  }, [dispatch]);

  useEffect(() => {
    if (!Array.isArray(ads) || ads.length === 0) return;

    const scrollerElement = scrollerRef.current;
    if (!scrollerElement) return;

    // Clone the ads for seamless scrolling
    const scrollerContent = Array.from(scrollerElement.children);
    scrollerContent.forEach(item => {
      const clone = item.cloneNode(true);
      scrollerElement.appendChild(clone);
    });

    // Calculate total scroll width and scroll speed
    const scrollWidth = scrollerElement.scrollWidth;
    const duration = scrollWidth * 10;
    // Create animation
    scrollerElement.animate(
      [
        { transform: 'translateX(0)' },
        { transform: `translateX(-${scrollWidth / 2}px)` }
      ],
      {
        duration: duration,
        iterations: Infinity,
        easing: 'linear'
      }
    );

    // Pause on hover
    const handleMouseEnter = () => {
      scrollerElement.getAnimations().forEach(animation => animation.pause());
    };

    const handleMouseLeave = () => {
      scrollerElement.getAnimations().forEach(animation => animation.play());
    };

    scrollerElement.addEventListener('mouseenter', handleMouseEnter);
    scrollerElement.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      scrollerElement.removeEventListener('mouseenter', handleMouseEnter);
      scrollerElement.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [ads]);

  if (loading) return <div>Loading ads...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!Array.isArray(ads) || ads.length === 0) return null;

  return (
    <div
      ref={containerRef}
      className="w-full overflow-hidden"
    >
      <div
        ref={scrollerRef}
        className="flex gap-4 px-4 whitespace-nowrap"
        style={{ willChange: 'transform' }}
      >
        {ads.map((ad) => (
          <div
            key={ad._id}
            className="flex-none w-80"
            style={{ minWidth: '320px' }}
          >
            <AdsCard ad={ad} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ads;
