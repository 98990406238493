import React from "react";

const PrivacyPolicyScreen = () => {
  return (
    <section className="bg-slate-950 text-gray-100 py-16 min-h-screen">
      <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
        <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">
          Privacy Policy
        </h2>
        <div className="container">
          <p>
            Carveel Car Wash ("we," "us," "our") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you visit our website
            www.carveel.com, use our services, or purchase our products. Please
            read this Privacy Policy carefully. If you do not agree with the
            terms of this Privacy Policy, please do not access the site.Carveel
            Car Wash ("we," "us," "our") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you visit our website
            www.carveel.com, use our services, or purchase our products. Please
            read this Privacy Policy carefully. If you do not agree with the
            terms of this Privacy Policy, please do not access the site.
          </p>
        </div>
        <div className="container mt-4 text-gray-100">
          <h2 className="text-2xl font-bold  text-gray-100">
            1. Information We Collect
          </h2>

          <p className="mb-4">
            We may collect information about you in a variety of ways. The
            information we may collect on the Site includes:
          </p>
          <h5 className="text-lg font-bold mb-2">Personal Data</h5>
          <p className="mb-4">
            Personally identifiable information, such as your name, shipping
            address, email address, and telephone number, and demographic
            information, such as your age, gender, hometown, and interests, that
            you voluntarily give to us when you register on the Site, make a
            purchase, or when you choose to participate in various activities
            related to the Site (such as online chat and message boards).
          </p>
          <h5 className="text-lg font-bold mb-2">Derivative Data</h5>
          <p className="mb-4">
            Information our servers automatically collect when you access the
            Site, such as your IP address, your browser type, your operating
            system, your access times, and the pages you have viewed directly
            before and after accessing the Site.
          </p>
          <h5 className="text-lg font-bold mb-2">Financial Data</h5>
          <p className="mb-4">
            Financial information, such as data related to your payment method
            (e.g., valid credit card number, card brand, expiration date) that
            we may collect when you purchase, order, return, exchange, or
            request information about our services from the Site.
          </p>
          <h2 className="text-2xl font-bold text-gray-100">
            2. Use of Your Information
          </h2>
          <p>
            Having accurate information about you permits us to provide you with
            a smooth, efficient, and customized experience. Specifically, we may
            use information collected about you via the Site to:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Create and manage your account.</li>
            <li>Email you regarding your account or order.</li>
            <li>Enable user-to-user communications.</li>
            <li>
              Generate a personal profile about you to make future visits more
              efficient.
            </li>
            <li>Increase the efficiency and operation of the Site.</li>
            <li>
              Monitor and analyze usage and trends to improve your experience
              with the Site.
            </li>
            <li>Notify you of updates to the Site.</li>
            <li>
              Offer new products, services, and/or recommendations to you.
            </li>
          </ul>
          <h2 className="text-2xl font-bold  text-gray-100">
            3. Disclosure of Your Information
          </h2>
          <p>
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follows:
          </p>
          <h5 className="text-lg font-bold mb-2">
            By Law or to Protect Rights
          </h5>
          <p className="mb-4">
            If we believe the release of information about you is necessary to
            respond to legal process, to investigate or remedy potential
            violations of our policies, or to protect the rights, property, and
            safety of others, we may share your information as permitted or
            required by any applicable law, rule, or regulation. This includes
            exchanging information with other entities for fraud protection and
            credit risk reduction.
          </p>
          <h5 className="text-lg font-bold mb-2">
            Third-Party Service Providers
          </h5>
          <p className="mb-4">
            We may share your information with third parties that perform
            services for us or on our behalf, including payment processing, data
            analysis, email delivery, hosting services, customer service, and
            marketing assistance.
          </p>
          <h5 className="text-lg font-bold mb-2">Marketing Communications</h5>
          <p className="mb-4">
            With your consent, or with an opportunity for you to withdraw
            consent, we may share your information with third parties for
            marketing purposes, as permitted by law.
          </p>
          <h5 className="text-lg font-bold mb-2">
            Interactions with Other Users
          </h5>
          <p className="mb-4">
            If you interact with other users of the Site, those users may see
            your name, profile photo, and descriptions of your activity.
          </p>
          <h2 className="text-2xl font-bold  text-gray-100">
            4. Security of Your Information
          </h2>
          <p>
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse.
          </p>
          <h2 className="text-2xl font-bold  text-gray-100">5. Contact Us</h2>
          <p>
            If you have questions or comments about this Privacy Policy, please
            contact us at:
          </p>
          <p>
            Carveel Car Wash
            <br />
            1234 Carveel Way
            <br />
            Auto City, AC 12345
            <br />
            Email:order@carveel.com
            <br />
            Phone: 123-456-7890
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicyScreen;
