import {
  Component,
  Suspense,
  lazy,
  startTransition,
  useEffect,
  useState,
  useTransition,
} from "react";
import { Route, Routes, BrowserRouter, useNavigate, useLocation } from "react-router-dom";
import { UserProvider } from "context/UserContext";
import ScrollToTop from "./ScrollToTop";
import Profile from "components/Dashboard/Profile";
import Loader from "components/Dashboard/Loader";
import OrderManager from "components/Dashboard/OrderManager";
import Login from "components/Login";
import UserManager from "components/Dashboard/UserManager";
import ProductManager from "components/Dashboard/ProductManager";
import AdsManager from "components/Dashboard/AdsManager";
import PackageManager from "components/Dashboard/PackageManager";
import TimeslotManager from "components/Dashboard/TimeslotManager";
import PromocodeManager from "components/Dashboard/PromocodeManager";
import PartnerManager from "components/Dashboard/PartnerManager";
import PageHome from "components/PageHome";
import Services from "components/Services";
import Products from "components/Products";
import Blog from "components/Blog";
import Partner from "components/Partner";
import PageSignUp from "components/SignUp";
import ServiceManager from "components/Dashboard/ServiceManager";
import BlogPost from "components/BlogPost";
import UserProfileScreen from "screens/UserProfileScreen";
import MyOrdersScreen from "screens/MyOrdersScreen";
import CartScreen from "screens/CartScreen";
import Payment from "screens/PaymentScreen";
import Success from "screens/Success";
import Cancel from "screens/Cancel";
import AboutUsScreen from "screens/AboutUsScreen";
import FaqScreen from "screens/FaqScreen";
import PrivacyPolicyScreen from "screens/PrivacyPolicyScreen";
import TermsAndConditonsScreen from "screens/TermsAndConditonsScreen";
import MainDash from "components/Dashboard/MainDash";
import VerifyLogin from "components/VerifyLogin";
import ReviewManager from "components/Dashboard/ReviewManager";
import ForgetPassword from "components/ForgetPassword";
import ResetPassword from "components/ResetPassword";

const publicRoutes = [
  { 
    path: "/forgot-password", 
    component: ForgetPassword 
  },
  {
    path: "/reset-password/:token", 
    component: ResetPassword 
  }
];
const mainRoutes = [
  {
    path: "/",
    title: "Home",
    component: PageHome,
  },
  {
    path: "/login",
    title: "Login",
    component: Login,
  },

  {
    path: "/signup",
    title: "Sign Up",
    component: PageSignUp,
  },
  {
    path: "/services",
    title: "Services",
    component: Services,
  },
  {
    path: "/products",
    title: "Products",
    component: Products,
  },
  {
    path: "/blog",
    title: "Blog",
    component: Blog,
  },
  {
    path: "/blog/:id",
    title: "Blog",
    component: BlogPost,
  },
  {
    path: "/partner",
    title: "Partner",
    component: Partner,
  },
  {
    path: "/profile",
    title: "Profile",
    component: UserProfileScreen,
  },
  {
    path: "/myorders",
    title: "My Orders",
    component: MyOrdersScreen,
  },
  {
    path: "/cart",
    title: "Cart",
    component: CartScreen,
  },
  {
    path: "/orders/:id/payment",
    title: "Payment",
    component: Payment,
  },
  {
    path: "/success",
    title: "Success",
    component: Success,
  },
  {
    path: "/cancel",
    title: "Cancel",
    component: Cancel,
  },
  {
    path: "/about-us",
    title: "About Us",
    component: AboutUsScreen,
  },

  {
    path: "/verify-login",
    title: "Verify Login",
    component: VerifyLogin,
  },
  {
    path: "/faq",
    title: "FAQ",
    component: FaqScreen,
  },
  {
    path: "/privacy-policy",
    title: "Privacy Policy",
    component: PrivacyPolicyScreen,
  },
  {
    path: "/terms-and-conditions",
    title: "Terms & Conditions",
    component: TermsAndConditonsScreen,
  },
];

const dashboardRoutes = [
  {
    path: "/dashboard/profile",
    title: "Profile",
    component: Profile,
  },
  {
    path: "/dashboard/user-manager",
    title: "User Manager",
    component: UserManager,
  },
  {
    path: "/dashboard/product-manager",
    title: "Product Manager",
    component: ProductManager,
  },
  {
    path: "/dashboard/ads-manager",
    title: "Ads Manager",
    component: AdsManager,
  },
  {
    path: "/dashboard/review-manager",
    title: "Review Manager",
    component: ReviewManager,
  },
  {
    path: "/dashboard/order-manager",
    title: "Order Manager",
    component: OrderManager,
  },
  {
    path: "/dashboard/package-manager",
    title: "Package Manager",
    component: PackageManager,
  },
  {
    path: "/dashboard/service-manager",
    title: "Service Manager",
    component: ServiceManager,
  },
  {
    path: "/dashboard/timeslot-manager",
    title: "Timeslot Manager",
    component: TimeslotManager,
  },
  {
    path: "/dashboard/promocode-manager",
    title: "Promocode Manager",
    component: PromocodeManager,
  },
  {
    path: "/dashboard/partner-manager",
    title: "Partner Manager",
    component: PartnerManager,
  },
];

const DefaultLayout = lazy(() => import("../components/Dashboard/DefaultLayout"));
const MainLayout = lazy(() => import("../components/MainLayout"));

// Create a wrapper component to handle layout transitions
const LayoutWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    if (location !== currentLocation) {
      startTransition(() => {
        setCurrentLocation(location);
      });
    }
  }, [location, currentLocation]);

  return (
    <Suspense fallback={<Loader />}>
      {children}
    </Suspense>
  );
};

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      startTransition(() => {
        setLoading(false);
      });
    }, 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <UserProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <LayoutWrapper>
                <MainLayout />
              </LayoutWrapper>
            }
          >
            <Route index element={<PageHome />} />
            {publicRoutes.map((route, index) => {
              const { path, component: Component } = route;
              return (
                <Route
                  key={`public-${index}`}
                  path={path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Component />
                    </Suspense>
                  }
                />
              );
            })}
            {mainRoutes
              .filter(route => 
                route.path !== '/forgot-pass' && 
                !route.path.includes('/reset-password/')
              )
              .map((route, index) => {
                const { path, component: Component } = route;
                return (
                  <Route
                    key={`main-${index}`}
                    path={path}
                    element={
                      <Suspense fallback={<Loader />}>
                        <Component />
                      </Suspense>
                    }
                  />
                );
            })}
          </Route>
          <Route
            path="/dashboard"
            element={
              <LayoutWrapper>
                <DefaultLayout />
              </LayoutWrapper>
            }
          >
            <Route index element={<MainDash />} />
            {dashboardRoutes.map((route, index) => {
              const { path, component: Component } = route;
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Component />
                    </Suspense>
                  }
                />
              );
            })}
          </Route>
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
