import React, { FC } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "images/travelHero1.jpg";
import image2 from "images/travelhero2.jpeg";
import image3 from "images/travelHero3.jpeg";
import image4 from "images/travelHero4.jpg";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Set the speed for autoplay in milliseconds (3000ms = 3 seconds)
  };

  const images = [image1, image2, image3, image4];

  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <div
              className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-4 xl:aspect-h-4 rounded-xl"
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
                <h2 className="font-bold text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl !leading-[115%] ">
                  Get your car washed <br /> at your Doorstep.
                </h2>
                <button
                  onClick={() => {
                    window.location.href = "/about-us";
                  }}
                  className="mt-4 px-4 py-2 bg-orange-500 text-white text-sm font-medium rounded hover:bg-orange-600"
                >
                  About Carveel Car Wash...
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        left: "10px", // Adjust positioning here
        zIndex: 1, // Ensure it's above the content
      }}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        right: "10px", // Adjust positioning here
        zIndex: 1, // Ensure it's above the content
      }}
      onClick={onClick}
    />
  );
};

export default SectionHero;
