import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { markOrderAsPaid } from "features/orders/orderSlice";
import { clearCart } from "features/cart/cartSlice";
const Success: React.FC = () => {
  const [paymentStatus, setPaymentStatus] = useState("");
  const dispatch = useAppDispatch();
  const { orders, loading, error } = useAppSelector((state) => state.orders);

  const location = useLocation();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get("session_id");
    const orderId = new URLSearchParams(location.search).get("order_id");

    if (sessionId) {
      axiosInstance
        .get(`/payment/status?session_id=${sessionId}`)
        .then((response) => {
          // Extract the payment status from the response data
          const paymentStatus = response.data.payment_status;

          // Update the payment status state
          setPaymentStatus(paymentStatus);
        })
        .catch((error) => {
          console.error("Error fetching payment status:", error);
        });
    }
    if (orderId) {
      dispatch(markOrderAsPaid({ orderId }));
      dispatch(clearCart());
    }
  }, [location.search]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen text-2xl text-green-500">
      <h1>Payment Successful!</h1>
      <p>Payment Status: {paymentStatus}</p>
      <p>Redirecting to home in {countdown} seconds...</p>
      <p>Thank you for your purchase.</p>
    </div>
  );
};

export default Success;
