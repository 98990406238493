// PackageManager.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import Input from "shared/Input/Input"; // Assume this is already styled with Tailwind CSS
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  createPackage,
  deletePackage,
  editPackage,
  fetchPackages,
} from "features/package/packageSlice";
import { NewPackage, Package, Detail } from "data/types";

const PackageManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { packages, loading, error } = useAppSelector(
    (state) => state.packages
  );
  console.log(packages);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCarType, setSelectedCarType] = useState<string>("Sedan");

  const [packageForm, setPackageForm] = useState<NewPackage>({
    name: "",
    price: 0,
    details: [{ title: "", content: "" }],
    carType: "Sedan",
  });

  // State to track the edited package
  const [editedPackage, setEditedPackage] = useState<Package | null>(null);

  const filteredPackages = packages.filter(
    (pkg) => pkg.carType === selectedCarType
  );

  const handleDetailChange = (
    detailIndex: number,
    field: keyof Detail,
    value: string
  ) => {
    setPackageForm((prevState) => ({
      ...prevState,
      details: prevState.details.map((detail, index) =>
        index === detailIndex ? { ...detail, [field]: value } : detail
      ),
    }));
  };

  const handleAddDetail = () => {
    setPackageForm((prevState) => ({
      ...prevState,
      details: [...prevState.details, { title: "", content: "" }],
    }));
  };

  const handleRemoveDetail = (detailIndex: number) => {
    setPackageForm((prevState) => ({
      ...prevState,
      details: prevState.details.filter((_, index) => index !== detailIndex),
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const target = event.target as HTMLInputElement; // Type assertion here
    const { name, value } = target;
    // Now TypeScript knows target could have a `files` property when it's an input

    setPackageForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to open edit modal and populate data
  const openEditModal = (packageToEdit: Package) => {
    // Populate the packageForm state with data from the package to edit
    setPackageForm({
      name: packageToEdit.name,
      price: packageToEdit.price,
      details: packageToEdit.details,
      carType: packageToEdit.carType,
    });

    setEditedPackage(packageToEdit);
    setIsModalOpen(true);
  };

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formPayload = {
      ...packageForm,
    };

    if (editedPackage) {
      // Edit existing package
      await dispatch(
        editPackage({ packageId: editedPackage._id, data: formPayload })
      );
    } else {
      // Create new package
      await dispatch(createPackage(formPayload));
    }

    // Re-fetch packages and close modal
    dispatch(fetchPackages());
    closeModal();
  };
  const deleteHandler = async (packageId: string) => {
    // Confirm deletion with the user
    if (window.confirm("Are you sure you want to delete this package?")) {
      await dispatch(deletePackage(packageId));
      // Optionally, refetch packages list after deletion
      dispatch(fetchPackages());
    }
  };

  const renderDetailContent = (content: string) => {
    // Split content by new lines and map to list items
    return content.split("\n").map((item, index) => (
      <li key={index} className="list-disc list-inside">
        {item}
      </li>
    ));
  };

  useEffect(() => {
    dispatch(fetchPackages());
  }, [dispatch]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Package</h2>
          <ButtonPrimary onClick={openModal} className="z-10">
            Create Package
          </ButtonPrimary>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Select Car Type:</label>
          <select
            value={selectedCarType}
            onChange={(e) => setSelectedCarType(e.target.value)}
            className="shadow border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="Sedan">Sedan</option>
            <option value="SUV">SUV</option>
            <option value="Pickup">Pickup</option>
          </select>
        </div>

        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Details</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPackages.map((packageItem: Package) => (
              <tr key={packageItem._id}>
                <td className="border px-4 py-2">{packageItem.name}</td>
                <td className="border px-4 py-2">{packageItem.price}</td>
                <td className="border px-4 py-2">
                  {packageItem.details.map((detail, index) => (
                    <div key={index}>
                      <strong>{detail.title}:</strong>
                      <ul>{renderDetailContent(detail.content)}</ul>
                    </div>
                  ))}
                </td>
                <td className="border px-4 py-2">
                  <button
                    className="text-blue-500 hover:text-blue-700 mr-2"
                    onClick={() => openEditModal(packageItem)} // Open edit modal with package data
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => deleteHandler(packageItem._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <p>Loading packages...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <form onSubmit={handleSubmit}>
            <div className="p-4">
              <h2 className="text-lg font-semibold mb-4">Create Package</h2>
              {/* Name input */}
              <div className="mb-4">
                <label className="block mb-2">Car Type</label>
                <select
                  name="carType"
                  value={packageForm.carType}
                  onChange={handleInputChange}
                  className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="Sedan">Sedan</option>
                  <option value="SUV">SUV</option>
                  <option value="Pickup">Pickup</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Name</label>
                <Input
                  name="name"
                  placeholder="Package Name"
                  value={packageForm.name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Price input */}
              <div className="mb-4">
                <label className="block mb-2">Price</label>
                <Input
                  name="price"
                  type="number"
                  placeholder="Package Price"
                  value={packageForm.price}
                  onChange={handleInputChange}
                />
              </div>

              {/* Description textarea */}
              {packageForm.details.map((detail, index) => (
                <div key={index} className="mb-4">
                  <label className="block mb-2">Detail Title {index + 1}</label>
                  <Input
                    name={`title-${index}`}
                    placeholder="Detail Title"
                    value={detail.title}
                    onChange={(e) =>
                      handleDetailChange(index, "title", e.target.value)
                    }
                  />
                  <label className="block mb-2">
                    Detail Content {index + 1}
                  </label>
                  <textarea
                    name={`content-${index}`}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Detail Content"
                    value={detail.content}
                    onChange={(e) =>
                      handleDetailChange(index, "content", e.target.value)
                    }
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveDetail(index)}
                    className="text-red-500"
                  >
                    Remove Detail
                  </button>
                </div>
              ))}
              <div className="flex flex-col space-y-4">
                <button
                  type="button"
                  onClick={handleAddDetail}
                  className="self-start text-sm font-semibold text-white"
                >
                  Add Another Detail
                </button>
                <ButtonPrimary type="submit" className="self-end">
                  Submit
                </ButtonPrimary>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default PackageManager;
