import React, { FC, useState } from "react";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAppDispatch } from "app/hooks";
import { login } from "features/user/userSlice";
import { useUser } from "context/UserContext";
import SmallLoader from "./Dashboard/SmallLoader";
import BgGlassmorphism from "./BgGlassmorphism";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [identifier, setIdentifier] = useState(""); // Email or phone
  const [password, setPassword] = useState(""); // Password for traditional login
  const [otp, setOtp] = useState(""); // OTP for phone login
  const [loading, setLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false); // Track if OTP has been sent
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { login: contextLogin, setUpRecaptcha, loginWithPhone } = useUser();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure name and value
    if (name === "identifier") {
      setIdentifier(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "otp") {
      setOtp(value);
    }
  };

  const formatPhoneNumber = (number: string): string => {
    // Remove any non-numeric characters
    const cleanNumber = number.replace(/\D/g, "");

    // If it starts with 0, replace with the country code
    if (cleanNumber.startsWith("0")) {
      return "+971" + cleanNumber.substring(1);
    }

    // If already in international format, return as is
    if (cleanNumber.startsWith("971")) {
      return "+" + cleanNumber; // Ensure it starts with a '+'
    }

    return cleanNumber; // Return unmodified if not starting with '0' or '971'
  };
  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");

    // Check if identifier is a phone number (you can customize this logic)
    const isPhoneNumber =
      identifier.startsWith("0") && identifier.length === 10; // Example check for local numbers

    // Format the phone number if it is detected as such
    const formattedIdentifier = isPhoneNumber
      ? `+971${identifier.slice(1)}`
      : identifier;

    try {
      if (isOtpSent) {
        // Attempt phone login with OTP
        await loginWithPhone(otp); // Use context's loginWithPhone
        navigate("/"); // Redirect after successful login
      } else {
        // Attempt traditional login with email/password
        const userData = await dispatch(
          login({ identifier: formattedIdentifier, password }) // Send formatted identifier
        ).unwrap();
        if (userData.token) {
          localStorage.setItem("token", userData.token);
        }

        const user = {
          email: userData.email,
          token: userData.token,
          _id: userData._id as string,
          name: userData.fullName || "Default Name", // Ensure name is included
          phoneNumber: userData.phoneNumber || "", // Ensure phoneNumber is a string
        };

        contextLogin(user); // Update context
        navigate("/");
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message || "Login failed. Please try again.");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendOtp = async () => {
    setPhoneLoading(true);
    try {
      const formattedPhoneNumber = formatPhoneNumber(identifier); // Format phone number before sending
      await setUpRecaptcha(formattedPhoneNumber); // Use the formatted phone number
      setIsOtpSent(true); // Set to true after sending OTP
      console.log("OTP sent!");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(
          error.message || "Failed to send OTP. Please try again."
        );
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    } finally {
      setPhoneLoading(false);
    }
  };

  return (
    <div className={`nc-PageLogin relative overflow-hidden ${className}`} data-nc-id="PageLogin">
      <BgGlassmorphism />
      <div className="container relative z-10 mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="bg-white/10 backdrop-blur-lg rounded-3xl p-8">
            <form className="grid grid-cols-1 gap-6" onSubmit={submitHandler}>
              <label className="block">
                <span className="text-neutral-200 font-medium">
                  Email or Phone Number
                </span>
                <Input
                  type="text"
                  name="identifier"
                  placeholder="example@email.com or +971 XX XXX XXXX"
                  className="mt-1.5 bg-white/5 border-neutral-500"
                  value={identifier}
                  onChange={handleChange}
                />
              </label>

              {!isOtpSent ? (
                <>
                  <label className="block">
                    <span className="flex justify-between items-center text-neutral-200 font-medium">
                      Password
                      <Link to="/forgot-password" className="text-sm text-primary-500 hover:text-primary-400">
                        Forgot password?
                      </Link>
                    </span>
                    <Input
                      type="password"
                      name="password"
                      placeholder="••••••••"
                      className="mt-1.5 bg-white/5 border-neutral-500"
                      value={password}
                      onChange={handleChange}
                    />
                  </label>

                  <div className="flex flex-col sm:flex-row gap-3">
                    <ButtonPrimary
                      type="submit"
                      disabled={loading}
                      className="flex-1 flex items-center justify-center"
                    >
                      {loading ? <SmallLoader /> : "Continue"}
                    </ButtonPrimary>
                    <ButtonPrimary
                      type="button"
                      onClick={sendOtp}
                      className="flex-1 flex items-center justify-center bg-secondary-600 hover:bg-secondary-500"
                    >
                      {phoneLoading ? <SmallLoader /> : "Send OTP"}
                    </ButtonPrimary>
                  </div>
                </>
              ) : (
                <>
                  <label className="block">
                    <span className="text-neutral-200 font-medium">
                      Enter OTP
                    </span>
                    <Input
                      type="text"
                      name="otp"
                      placeholder="Enter the OTP sent to your phone"
                      className="mt-1.5 bg-white/5 border-neutral-500"
                      value={otp}
                      onChange={handleChange}
                    />
                  </label>
                  <ButtonPrimary type="submit" disabled={loading}>
                    {loading ? <SmallLoader /> : "Verify OTP"}
                  </ButtonPrimary>
                </>
              )}
            </form>
            {errorMessage && (
              <div className="mt-4 text-red-400 text-sm text-center">{errorMessage}</div>
            )}
          </div>

          <span className="block text-center text-neutral-300">
            New user?{" "}
            <Link to="/signup" className="text-primary-500 hover:text-primary-400 font-medium">
              Create an account
            </Link>
          </span>
          <div id="recaptcha-container"></div>
        </div>
      </div>
    </div>
  );
};
export default PageLogin;
