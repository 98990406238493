// PackageManager.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import Input from "shared/Input/Input"; // Assume this is already styled with Tailwind CSS
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  createPromoCode,
  deletePromoCode,
  editPromoCode,
  fetchPromoCodes,
  updateIsActive,
} from "features/promocode/promoCodeSlice";
import { NewPromoCode, PromoCode } from "data/types";

const PromocodeManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { promocodes, loading, error } = useAppSelector(
    (state) => state.promocodes
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [promoCodeForm, setPromoCodeForm] = useState<NewPromoCode>({
    name: "",
    discountType: "PERCENTAGE", 
    discountValue: 0,
    code: "",
    expiryDate: "",
    isActive: true,
    maxUsage: 0
  });

  // State to track the edited package
  const [editedPackage, setEditedPromoCode] = useState<PromoCode | null>(null);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement; // Type assertion here
    const { name, value } = target;
    // Now TypeScript knows target could have a `files` property when it's an input

    setPromoCodeForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to open edit modal and populate data
  const openEditModal = (promoCodeToEdit: PromoCode) => {
    // Populate the promoCodeForm state with data from the package to edit
    setPromoCodeForm({
      name: promoCodeToEdit.name,
      discountType: promoCodeToEdit.discountType, // Include the image property
      discountValue: promoCodeToEdit.discountValue,
      code: promoCodeToEdit.code,
      expiryDate: promoCodeToEdit.expiryDate,
      isActive: promoCodeToEdit.isActive,
      maxUsage:promoCodeToEdit.maxUsage,
    });

    setEditedPromoCode(promoCodeToEdit);
    setIsModalOpen(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Check if we are editing an existing package
    if (editedPackage) {
      // Perform edit operation

      dispatch(
        editPromoCode({
          promocodeId: editedPackage._id,
          data: {
            ...promoCodeForm,
          },
        })
      );
      dispatch(fetchPromoCodes());
    } else {
      dispatch(
        createPromoCode({
          ...promoCodeForm,
        })
      );
    }
    closeModal();
  };

  const handleIsActiveChange = (promocodeId: string, isActive: boolean) => {
    dispatch(updateIsActive({ promocodeId, data: { isActive: !isActive } }));
    // Optional: Update local state to reflect changes immediately
    // This could be done by fetching all promo codes again or directly manipulating the state
  };

  const deleteHandler = async (promocodeId: string) => {
    // Confirm deletion with the user
    if (window.confirm("Are you sure you want to delete this package?")) {
      await dispatch(deletePromoCode(promocodeId));
      // Optionally, refetch packages list after deletion
      dispatch(fetchPromoCodes());
    }
  };

  useEffect(() => {
    dispatch(fetchPromoCodes());
  }, [dispatch]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className=" p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">PromoCode</h2>
          <ButtonPrimary onClick={openModal} className="z-10">
            Create PromoCode
          </ButtonPrimary>
        </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Discount Type</th>
              <th className="px-4 py-2">Discount Value</th>
              <th className="px-4 py-2">Code</th>
              <th className="px-4 py-2">Expiry Date</th>
              <th className="px-4 py-2">Is Active</th>
            </tr>
          </thead>
          <tbody>
            {promocodes.map((promoCodeItem: PromoCode) => (
              <tr key={promoCodeItem._id}>
                <td className="border px-4 py-2">{promoCodeItem.name}</td>
                <td className="border px-4 py-2">
                  {promoCodeItem.discountType}
                </td>
                <td className="border px-4 py-2">
                  {promoCodeItem.discountValue}
                </td>
                <td className="border px-4 py-2">{promoCodeItem.code}</td>
                <td className="border px-4 py-2">{promoCodeItem.expiryDate}</td>
                <td className="border px-4 py-2">
                  <input
                    type="checkbox"
                    checked={promoCodeItem.isActive}
                    onChange={() =>
                      handleIsActiveChange(
                        promoCodeItem._id,
                        promoCodeItem.isActive
                      )
                    }
                    // Pass the promo code ID and the opposite of its current isActive state
                  />
                </td>
                <td className="border px-4 py-2">
                  <button
                    className="text-blue-500 hover:text-blue-700 mr-2"
                    onClick={() => openEditModal(promoCodeItem)} // Open edit modal with package data
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => deleteHandler(promoCodeItem._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <p>Loading packages...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <form onSubmit={handleSubmit}>
            <div className="p-4">
              <h2 className="text-lg font-semibold mb-4">Create Package</h2>
              {/* Name input */}
              <div className="mb-4">
                <label className="block mb-2">Name</label>
                <Input
                  name="name"
                  placeholder="Package Name"
                  value={promoCodeForm.name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Price input */}
              <div className="mb-4">
                <label className="block mb-2">Discount Type</label>
                <select
                  name="discountType"
                  className="block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={promoCodeForm.discountType || "PERCENTAGE"} // Default to PERCENTAGE
                  onChange={(event) =>
                    setPromoCodeForm((prevState) => ({
                      ...prevState,
                      discountType: event.target.value,
                    }))
                  }
                >
                  <option value="PERCENTAGE">Percentage</option>
                  <option value="FIXED">Fixed</option>
                </select>
              </div>


              {/* Discount Value input */}
              <div className="mb-4">
                <label className="block mb-2">Discount Value</label>
                <Input
                  name="discountValue" // Ensure this matches the state object's key
                  type="number"
                  placeholder="Discount Value"
                  value={promoCodeForm.discountValue}
                  onChange={handleInputChange}
                />
              </div>

              {/* Code input */}
              <div className="mb-4">
                <label className="block mb-2">Code</label>
                <Input
                  name="code" // Ensure this matches the state object's key
                  type="text"
                  placeholder="Code"
                  value={promoCodeForm.code}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-4">
                <label className="block mb-2">Expiry Date</label>
                <Input
                  name="expiryDate" // Ensure this matches the state object's key
                  type="date" // Change type to "date"
                  placeholder="Expiry Date"
                  value={promoCodeForm.expiryDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
              <label className="block mb-2">Max Usage</label>
              <Input
                name="maxUsage"
                type="number"
                placeholder="Max Usage"
                value={promoCodeForm.maxUsage}
                onChange={handleInputChange}
              />
            </div>

              <ButtonPrimary type="submit">Submit</ButtonPrimary>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default PromocodeManager;
