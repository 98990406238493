import React from "react";

const TermsAndConditionsScreen = () => {
  return (
    <section className="bg-slate-950 text-gray-100 py-16 min-h-screen">
      <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
        <h2 className="mb-12 text-4xl font-bold leading-tight text-center sm:text-5xl">
          Terms and Conditions
        </h2>

        <div className="container mx-auto space-y-6 text-gray-300">
          {/* Welcome Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Welcome</h3>
            <p>
              Welcome to www.carveel.com. The www.carveel.com website (the "Site") is operated by CARVEEL Car Wash LLC ("carveel.com").
              www.carveel.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms").
              Your use of www.carveel.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.
            </p>
            <p>
              www.carveel.com is a Mobile Auto Detailing Site. carveel.com provides customers the option to easily schedule and pay for their mobile detailing service online.
              Using or accessing any of the services offered by CARVEEL through the Website and/or the App (the “Services”) indicates your acceptance of these Terms of Use without the need for your handwritten signature. Therefore, if you do not accept these Terms of Use, generally or partially, please do not continue to use or access the Services.
            </p>
          </div>

          {/* Your Account Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Your Account</h3>
            <p>
              If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity.
            </p>
            <p>
              You acknowledge that carveel.com is not responsible for third-party access to your account that results from theft or misappropriation of your account. carveel.com and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.
            </p>
          </div>

          {/* Wash Packages Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Wash Packages</h3>
            <p>
              Prices and wash packages are subject to change at the discretion of CARVEEL; all such changes will be reflected on www.carveel.com and via Mobile App.
            </p>
          </div>

          {/* Payment Terms Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Payment Terms</h3>
            <p>
              Bookings are charged upon completion of the services requested. CARVEEL is a cashless service and may only process payment through a valid debit/credit card.
            </p>
            <p>
              Any card declined will lead CARVEEL to ask the customer to find a solution for the payment of the service provided. CARVEEL will start a collection process 48 hours after getting the customer’s Credit Card refused.
            </p>
            <p>
              CARVEEL reserves the right to charge additionally or refuse servicing of any commercially large vehicles and other transit vehicles.
            </p>
          </div>

          {/* Late Cancellation & Reschedule Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Late Cancellation & Reschedule</h3>
            <p>
              Any change/cancellation to the order shall be made at least two (2) hours prior to the chosen Time-Slot (the “Timeline”). Otherwise, the order will be delivered as per your initial request. However, if you wish to cancel the order after the Timeline, CARVEEL may charge you a late cancellation fee.
            </p>
            <p>
              CARVEEL will reserve the right to cancel the order at any time at its sole discretion and shall notify you on the cancellation via text messages or the Platform. No amount will be charged in such a case.
            </p>
            <p>
              You can reschedule or cancel within 15 minutes after you place your booking, free of charge. After 15 minutes, fees apply as follows:
            </p>
            <ul className="ml-8 list-disc space-y-2">
              <li>AED 10 flat fee for same-day booking reschedule or cancellation.</li>
              <li>A fee of 50% of the booking price when rescheduled or canceled within 2 hours of the booking due.</li>
              <li>
                A full charge of the booking will apply under circumstances where the vehicle is not present or accessible, 30 minutes past the booking due.
              </li>
            </ul>
            <p>
              By enrolling in a membership program, you acknowledge that you may be subject to various cancellation fees, which are outlined in the terms specific to your chosen plan.
            </p>
          </div>

          {/* Permits Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Permits</h3>
            <p>
              If any permits are required at the premises, it is the customer’s responsibility to arrange them. In the event that our service technician is unable to perform the service due to a permit issue, the full service charge will still be applied. Please note, our service technician will wait for a maximum of 15 minutes before leaving the premises.
            </p>
          </div>

          {/* Late Editing & Cancellation Booking Services Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Late Editing & Cancellation Booking Services</h3>
            <p>
              You can edit or cancel your booking service within 15 minutes after placing your booking, free of charge. After 15 minutes, fees apply as follows:
            </p>
            <ul className="ml-8 list-disc space-y-2">
              <li>AED 20 flat fee for same-day booking service editing to lower price or cancellation.</li>
              <li>
                A fee of 50% of the booking price when editing to lower price or cancellation within 2 hours of the booking due.
              </li>
            </ul>
          </div>

          {/* Membership Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Membership</h3>
            <p>
              CARVEEL may offer on-demand, monthly plans, and annual plans. By subscribing to these plans, you agree to the terms, including recurring payments, minimum commitment periods, and applicable additional charges.
            </p>
            <ul className="ml-8 list-disc space-y-2">
              <li>
                Membership costs start on the day you sign up, offering a complimentary wash per billing cycle.
              </li>
              <li>Minimum membership period is 6 months. Early termination fees apply (equal to one month's subscription).</li>
              <li>Membership pauses are allowed once every 6 months for one month.</li>
              <li>Missed washes due to unavailability are non-refundable and cannot be rescheduled.</li>
            </ul>
          </div>

          {/* Satisfaction Policy Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Satisfaction Policy</h3>
            <p>
              If you are dissatisfied with a service, you may submit a formal complaint within 48 hours by completing a form sent via email.
            </p>
            <p>
              If clear evidence of poor service is provided, we will redo the unsatisfactory portion or offer a refund.
            </p>
            <p>
              Failure to disclose significant issues (e.g., strong odors, mold) voids our service guarantee.
            </p>
          </div>

          {/* Damages and Liability Issues Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Damages and Liability Issues</h3>
            <p>
              CARVEEL is comprised of a network of independent contractors that are fully vetted. In unique cases of liability or damage, CARVEEL will aid in gathering information and mediating the process to best resolve any liability issues that occurred during servicing. CARVEEL, as a platform, is not liable for any lost, stolen, or damaged property.
            </p>
            <p>
              You shall inspect your vehicle within 24 hours of the provision of services. Complaints should be raised within this timeframe.
            </p>
          </div>

          {/* Rain Checks Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Rain Checks</h3>
            <p>
              CARVEEL cannot control natural elements, such as weather, and therefore does not offer any rain checks. Users are encouraged to check the weather prior to booking.
            </p>
          </div>

          {/* Appointment Time Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Appointment Time</h3>
            <p>
              Due to traffic or exceptional circumstances, detailers may arrive up to 15 minutes late. You may cancel or reschedule any appointment free of charge if a CARVEEL detailer is later than 30 minutes without prior notice.
            </p>
            <p>
              CARVEEL will do its best to inform users about any delays. All personal items and physical trash should be removed from the vehicle prior to servicing. Detailers reserve the right to delay or cancel service upon arrival if the vehicle is not prepared.
            </p>
          </div>

          {/* Coupon & Referral Policy Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">Coupon & Referral Policy</h3>
            <p>
              From time to time, CARVEEL may offer referral programs that enable you to earn credits or coupons. To qualify for credits, the referred customer must be a new customer and use your Referral Link to sign up for the services.
            </p>
            <p>
              You may not collect referrals by:
            </p>
            <ul className="ml-8 list-disc space-y-2">
              <li>Self-referring using multiple or false identities, accounts, or email addresses.</li>
              <li>Referring existing CARVEEL customers, even with alternate accounts.</li>
              <li>Using spam, unsolicited emails, or violating any applicable law or terms of use.</li>
              <li>Posting referral links on public forums, coupon sites, or other online venues.</li>
              <li>Providing false or misleading information to CARVEEL.</li>
              <li>Engaging in any other activities that portray CARVEEL negatively or violate program restrictions.</li>
            </ul>
            <p>
              CARVEEL reserves the right to cancel or rescind credits and void participation in referral programs if these terms are violated. Violations may result in civil or criminal penalties under applicable law.
            </p>
          </div>

          {/* General Section */}
          <div className="space-y-4">
            <h3 className="text-2xl font-semibold text-gray-100">General</h3>
            <p>
              Users must remove all valuables, property, and belongings from their vehicles before servicing. CARVEEL is not liable for any loss or damage caused to items left inside the vehicle.
            </p>
            <p>
              You confirm that you have a spare set of keys for your vehicle. CARVEEL is not liable for loss or damage caused by lost or locked keys.
            </p>
            <p>
              Child seats or other accessories must be removed prior to servicing. CARVEEL is not responsible for damage caused to these items or for refitting them after service.
            </p>
          </div>



        </div>
      </div>
    </section>
  );
};

export default TermsAndConditionsScreen;
