import React from "react";

const FaqScreen = () => {
  return (
    <section className="bg-slate-950 text-gray-100 py-32 min-h-screen">
      <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
        <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">
          Frequently Asked Questions
        </h2>
        <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700">
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              What is Carveel Car Wash?
            </summary>
            <div className="px-4 pb-4">
              <p>
                Carveel Car Wash is your one-stop destination for premium auto
                detailing services and high-quality car care products. We offer
                a range of services from exterior and interior detailing to
                specialized treatments like paint correction and ceramic
                coating. Additionally, we provide a selection of top-tier car
                care products for DIY enthusiasts.
              </p>
            </div>
          </details>
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              What services do you offer?
            </summary>
            <div className="px-4 pb-4">
              <p>
                We offer a comprehensive range of auto detailing services,
                including:
              </p>
              <ul className="list-disc list-inside">
                <li>Exterior Detailing</li>
                <li>Interior Detailing</li>
                <li>Paint Correction</li>
                <li>Ceramic Coating</li>
                <li>Headlight Restoration</li>
                <li>Engine Bay Detailing</li>
                <li>And more!</li>
              </ul>
            </div>
          </details>
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              How long does a full detailing service take?{" "}
            </summary>
            <div className="px-4 pb-4">
              <p>
                The duration of a full detailing service can vary depending on
                the size and condition of your vehicle. On average, a complete
                detailing service can take anywhere from 4 to 8 hours. For more
                accurate timing estimates, please contact our team.
              </p>
            </div>
          </details>
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              Do I need to make an appointment?
            </summary>
            <div className="px-4 pb-4">
              <p>
                Yes, we recommend making an appointment to ensure that we can
                accommodate your vehicle and provide the best service possible.
                You can schedule an appointment by contacting us via phone or
                email.
              </p>
            </div>
          </details>
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              What types of products do you sell?
            </summary>
            <div className="px-4 pb-4">
              <p>
                We offer a variety of high-quality car care products, including:
              </p>
              <ul className="list-disc list-inside">
                <li>Car Shampoo</li>
                <li>Car Wax</li>
                <li>Interior Cleaners</li>
                <li>Wheel Cleaners</li>
                <li>Microfiber Towels</li>
                <li>And more!</li>
              </ul>
            </div>
          </details>
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              Are your products available for purchase online?
            </summary>
            <div className="px-4 pb-4">
              <p>
                Yes, our car care products are available for purchase online.
                You can browse our selection of products on our website and
                place an order for delivery or pickup.
              </p>
            </div>
          </details>
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              Can I get advice on which products to use for my car?
            </summary>
            <div className="px-4 pb-4">
              <p>
                Absolutely! Our team of car care experts is here to help you
                choose the right products for your vehicle. Whether you need
                advice on cleaning, polishing, or protecting your car, we can
                provide personalized recommendations to suit your needs.
              </p>
            </div>
          </details>
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              What packages do you offer?
            </summary>
            <div className="px-4 pb-4">
              <p>
                We offer a range of detailing packages to suit different budgets
                and needs, including:
              </p>
              <ul className="list-disc list-inside">
                <li>Silver Package</li>
                <li>Gold Package</li>
                <li>Platinum Package</li>
              </ul>
              <p>
                Each package includes a combination of exterior and interior
                detailing services, with the option to add on specialized
                treatments like paint correction and ceramic coating.
              </p>
            </div>
          </details>
          <details>
            <summary className="py-2 outline-none cursor-pointer focus:underline">
              How can I contact Carveel Car Wash?
            </summary>
            <div className="px-4 pb-4">
              <p>
                You can contact Carveel Car Wash by sending us a message on
                WhatsApp or emailing us at orders@carveel.com for inquiries,
                appointments, or product orders. Our team will be happy to
                assist you with any questions or requests you may have.
              </p>
            </div>
          </details>
        </div>
      </div>
    </section>
  );
};

export default FaqScreen;
