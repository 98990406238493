import React from 'react';
import { Review } from 'data/types';
import google_logo from 'google_logo.png'

interface ReviewCardProps {
    review: Review;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ review }) => {
    const renderStars = (rating: number) => (
        <div className="flex justify-center mb-2">
            {[...Array(5)].map((_, index) => (
                <span
                    key={index}
                    className={`text-lg ${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}
                >
                    ★
                </span>
            ))}
        </div>
    );

    // New logo URL (you can replace this with the actual logo URL)
    const logoUrl = "google_logo.png"; // Update with the actual logo path

    return (
        <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-300 flex flex-col items-center text-center w-full max-w-sm h-auto">
            <img src={logoUrl} alt="Logo" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-bold text-gray-800 mb-2 truncate w-full">
                {review.author_name}
            </h3>
            {renderStars(review.rating)}
            <p
                className="text-gray-700 leading-relaxed text-sm mt-2 break-words"
                style={{
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'normal',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    height: '80px',
                    lineHeight: '1.5em',
                }}
            >
                {review.text}
            </p>
        </div>
    );
};

export default ReviewCard;
